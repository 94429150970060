import './subscribe.js';
import './timer.js';

import( './mods/links.js' );

let PROJECTNAME = 'sphere',
	focusLostCount = 0;

Map.prototype.parseMSET = function( str ) {
	if( !str ) {
		// Считаем пустую строку сбросом. Это сделано 02/12/21, следует проверить не возникнет ли ошибок
		// причина в том, что в гейте пустая строка приходит при пустом SET
		if( !this.size ) return;
		this.clear();
		return true;
	}
	window.log?.( 'Map parse: [' + str + ']' );
	if( typeof str==='object' ) return log( 'Stringify: ' + JSON.stringify( [...str] ) );
	let changed = 0;
	// Достаточно разделять по пробелу
	// for( let t of str.split( /[\s\,]/ ) ) {
	for( let t of str.split( ' ' ) ) {
		if( !t ) break;
		if( t==='-' && this.size ) {
			changed++;
			this.clear();
			continue;
		}
		if( t[0]==='-' ) {
			let id = t.slice( 1 );
			changed += this.delete( id );
			continue;
		}
		let parts = t.split( '=', 2 ),
			key = parts[0];
		if( parts.length===2 && this.get( key )===parts[1] ) continue;
		else if( parts.length===1 && this.has( key ) ) continue;
		this.set( parts[0], parts[1] );
		changed++;
	}
	return changed;
};

Set.prototype.parseMSET = function( str ) {
	let changed = 0;
	if( !str ) {
		if( !this.size ) return false;
		this.clear();
		return true;
	}
	for( let t of str.split( ' ' ) ) {
		if( !t ) break;
		if( t==='-' && this.size ) {
			changed++;
			this.clear();
			continue;
		}
		if( t[0]==='-' ) {
			let id = t.slice( 1 );
			changed += this.delete( id );
			continue;
		}
		this.add( t );
		changed++;
	}
	return changed;
};

import './lang.js';
import './support.js';
import {JSON5} from './json5.js';
import Subscribe from './subscribe.js';
import Swiper from './swiper.js';
import './user.js';

// return new function() {
let localaliases = localStorage.aliases,
	alocal = localaliases && JSON.parse( localaliases ),
	Core = {
		modules: {},
		mySelf: {},
		global: {},
		params: window.coreParams || {},
		plays: {},
		myfants: { value: 0 },
		premium: false,
		config: {},
		checkCanPlay: name => ['board', 'cards', 'domino', 'genium', 'asker', 'chipoker', 'chio', 'gammon', 'bg'].includes( name ),
		globalAttr: {
			bg: 'canva',
			sounds: true,
			premove: true
		},
		myRegs: new Map,
		myRegers: new Map,
		myFriends: new Map,
		myMutes: new Set,
		myNoplay: new Set,
		myTeamsMap: new Map,
		myGames: new Map,
		myTD: new Map,
		myBans: '',
		tourPlacing: new Map,
		aliases: new Map( Array.isArray( alocal ) && alocal || null ),
		location: null,
		petitionData: {},
		way: [],
	},
	storagePrefix = '',
	docready = false,

	//--- Main Navigation
	land,

	//---
	lastPlayMs = 0;

window.elephCore = Core;

if( LOCALTEST ) {
	Core.params.tableviewstart = '';
}

export default Core;

try {
	let attr = localStorage.attributes;
	if( attr ) {
		let o = JSON.parse( attr );
		window.startAttributes = { ...o };
		for( let k in o ) {
			setAttr( k, o[k], 'onstart' );
		}
	}
} catch( e ) {
	window.NOLOCALSTORAGE = JSON.stringify( e );
	console.warn( `Failed localStorage ${JSON.stringify( e )}` );
	log( `Failed localStorage ${JSON.stringify( e )}` );
	window.toast?.( `localStorage is not available!` );
}

Core.getStorage = item => {
	return localStorage[storagePrefix + item];
};

Core.setStorage = ( item, value ) => {
	if( value===undefined )
		delete localStorage[storagePrefix + item];
	else
		localStorage[storagePrefix + item] = value;
};

function renewAliases() {
	if( window.EXTERNALDOMAIN ) return;
	let roomAlias = window.coreParams.roomAlias;

	for( let k in roomAlias ) {
		let alias = roomAlias[k], id = k;
		if( !Core.aliases.has( alias ) ) Core.aliases.set( alias, id );
	}

	// if( serverAliases ) for( let [alias,id] of serverAliases ) {
	// 	if( !Core.aliases.has( alias ) ) Core.aliases.set( alias, id );
	// }

	for( let [alias, value] of Core.aliases ) {
		if( alias.includes( '-' ) ) continue;
		if( !DOMAIN.match( /gambler\.ru|gamblergames\.com/ ) && alias==='home' ) alias = '';
		Core.backAlias.set( value, alias );
		// if( alias.includes( '#' ) )
		// 	Core.aliases.set( alias.replace( '#', '-' ), value );
	}
}

Core.storeAliases = () => {
	localStorage['aliases'] = JSON.stringify( [...Core.aliases] );
	Core.aliases.__changed = 0;
};

Core.setAlias = ( alias, id ) => {
	// Если такой алиас существует, и корректент, ничего не делаем
	// if( id.startsWith( 'room_' ) ) id = id.slice( 5 );
	if( Core.aliases.get( alias )===id ) return;
	log( `Adding alias ${alias}==>${id}'` );
	if( alias ) {
		Core.aliases.set( alias, id );
		Core.backAlias.set( id, alias );
	} else {
		Core.backAlias.delete( id );
		log( `Removing alias for ${id}` );
	}
	delay( Core.storeAliases );
};

async function onload( e ) {
	log( "Core: onload" );
	Core.backAlias = new Map;
	let startPath = location.pathname.replace( '//', '/' );
	Core.pathPrefix = startPath + '?';

	if( !LOCAL ) {
		// if( startPath==='/play' ) startPath = '/play/';
		Core.pathPrefix = startPath.slice( 0, startPath.indexOf( '/', 6 ) + 1 );
		if( window.coreParams.playurl ) Core.pathPrefix = window.coreParams.playurl;
	}

	renewAliases();

	if( LOCALTEST ) window.IOS = true;

	// FIX localStorage overflow bug
	for( let k in localStorage ) {
		if( k.startsWith( 'chat_' ) || k.startsWith( 'chatparam_' ) )
			delete localStorage[k];
	}

	// document.body.style.height = window.screen.height + 'px';
	// Заполним массив сначала из параметров BODY, затем перекроем из адреса

	let attrs = document.body.dataset;
	for( let k in attrs ) {
		if( k==='playelephant' || k.indexOf( 'playelephant' )=== -1 ) continue;
		let s = k.replace( 'playelephant', '' ).toLowerCase();
		Core.params[s] = attrs[k];
	}

	// fill URL parameters

	let ls = LOCALTEST ? window.location.search.slice( 1 ) : location.pathname.slice( location.pathname.lastIndexOf( '/' ) + 1 ),
		wsearch = ls + window.location.hash,
		alias = Core.backAlias.get( wsearch ),
		search = alias || wsearch;
	log( 'Search is ' + search + ', wsearch: ' + wsearch );
	Core.getParams = {};
	Core.itemCode = null;
	search.split( '&' ).forEach(
		function( b ) {
			let a = b.split( '=' );
			if( !a[1] ) {
				Core.itemCode ||= a[0];
				return;
			}
			let key = a[0];
			try {
				let val = decodeURIComponent( a[1] );
				Core.getParams[key] = val;
				params[key] = val;
			} catch( e ) {
			}
		} );
	// Теперь перекроем из параметров URL
	Core.itemCode ||= Core.params['view'] || '';
	if( Core.itemCode.match( /^\d+\-/ ) ) Core.itemCode = Core.itemCode.split( '-' )[0];
	// Не нужно превращать itemCode
	// Core.itemCode = Core.aliases.get( Core.itemCode ) || Core.itemCode;

	fire( 'ready', e );
	docready = true;

	let o_land = document.querySelector( '[data-playelephant-land]' ) || document.body;
	if( o_land ) {
		// Загружаем скрипты
		Core.swiper = Swiper;
		Swiper.setParent( o_land );
		land = o_land.getAttribute( 'data-playelephant-land' );
		/* if( land!=='genium' ) */
		land = 'manager';
		import( './manager.js' ).then( Module => {
			// if( Manager.holder )
			// 	o_land.appendChild( Manager.holder );
			// else
			Module.default( Core.swiper );
		} );
	}

	document.addEventListener( 'deviceready', onDeviceReady, false );

	// Проверка действий, указанных в ссылке
	(async function checkStartActions() {
		let b64 = GETparams.get( 'blin' ) || GETparams.get( 'neo' );
		if( b64 )
			GETparams = new URLSearchParams( atob( b64 ) );
		if( b64 || window.location.pathname==='/view' || GETparams.has( 'view' ) || GETparams.has( 'viewer' ) || GETparams.has( 'n' ) || GETparams.has( 'v' ) || GETparams.has( 'a' ) ) {
			import( './mods/solo.js' ).then( mod => {
				let obj = Object.fromEntries( GETparams );
				delete obj.view;
				if( !Object.keys( obj ).length )
					obj.onload = true;
				mod.soloGo( {
					...obj,
					// mode: 'view',
					// location: '?' + url,
					godmode: true
				} );
			} );
			return;
		}
		if( !NEOBRIDGE ) {
			if( GETparams.has( 'p' ) && GETparams.has( 'game' ) ) {
				// Просмотр протокола, не открываем соединение
				petitionUrls.set( 'viewprotocol', location.href );
				(await import( './mods/gameplay.js' )).makeProtogame( GETparams );
				return;
			}
		}

		let intent = GETparams.get( 'q' );
		if( intent && location.hash ) intent += location.hash;
		if( location.search.match( /^\?https?:\/\// ) )
			intent = location.search.slice( 1 );
		if( intent ) {
			// Ask service for parse page
			(await import( './mods/intent.js' )).intent( intent );
			return;
		}

/*
		// uniq data начинается с d
		if( location.search.startsWith( '?d' ) && location.search.length===34 ) {
			// Попробуем загрузить
			(await import( './intent.js' )).intent( location.search.slice( 1 ) );
			return;
		}
*/

		// Пока в остальных случаях соединяемся
		if( window.SOLO )
			import( './mods/solo.js' ).then( mod => mod.onstart() );
		else
			import( './socket.js' );


		let dostr = GETparams.get( "doaction" ),
			doar = dostr?.split( '.' );

		switch( doar && doar[0] ) {
			case 'clonetournament':
				(await import( './mods/touredit.js' )).clone( doar[1] );
				break;
			case 'createtournament':
				if( doar[2] ) golocation( doar[2] );
				let params = {
					game: doar[1],
					tid: doar[2],
					type: doar[3]
				};
				for( let p of GETparams ) {
					if( p[0]!=='doaction' ) params[p[0]] = p[1];
				}
				// await Core.checkAuth( 'complete' );
				(await import( './mods/touredit.js' )).create( params );
				break;
		}

		let chatid = GETparams.get( "openchat" );
		if( chatid )
			import( './mods/userinfo.js' ).then( mod => {
				mod.default( chatid, null, 'showchat' )
			} );

		let tourid = GETparams.get( "replaytour" );
		if( tourid ) {
			// Открыть стол (пока только с переигровкой турнира)
			// делаем это после полной прогрузки скриптов и авторизации
			await Core.checkAuth( 'complete' );
			await waitFor( 'connected' );
			let team = GETparams.get( "team" ) || '';
			Core.sendPlay( `type=friendgame data="tourid=${tourid} team=${team} mode=${GETparams.get( "mode" ) || ''}"`, true );
			return;
		}

		tourid = GETparams.get( "startlesson" );
		if( tourid ) {
			// Открыть стол (пока только с переигровкой турнира)
			// делаем это после полной прогрузки скриптов и авторизации
			await Core.checkAuth( 'complete' );
			await waitFor( 'connected' );
			Core.sendPlay( `type=startlesson data="tourid=${tourid}"`, true );
			return;
		}

		// Покажем всплытие
		let t = GETparams.get( 'toast' );
		t && toast( t );

		if( GETparams.has( 'uploadorigin' ) ) {
			let m = await import( './mods/upload.js' );
			m.doupload( startParams.get( 'uploadorigin' ), startParams.get( 'uploadtype' ) );
		}

		if( GETparams.has( 'uploadavatar' ) ) {
			dispatch( 'loggedin' ).then( async () => {
				// Вижу ошибку - загрузка аватара может вызваться дважды после перелогина
				if( window.UIN ) {
					let m = await import( './mods/upload.js' );
					m.doupload( 'user.' + UIN, 'avatar' );
				}
			} );
		}
	})();
}

Core.setBack = str => {
	if( Core.way?.length===1 && Core.way[0]===str ) return;
	Core.way = str && [str] || null;
};

Core.goBack = () => {
	if( closeTopBigwindow() ) return true;
	log( 'Goback way: ' + JSON.stringify( Core.way ) );
	let action = Core.way?.pop();
	if( action ) {
		if( typeof action==='function' ) {
			log( 'Go back action' );
			action();
		} else if( typeof action==='object' ) {
			fire( action[0], action[1] );
		} else {
			log( 'Go back fire ' + action );
			fire( action );
		}
		return true;
	}
};

Core.showSettings = window.showSettings = game => {
	if( Core.noSettings ) return;
	if( game instanceof Event )
		game = game.target.closest( '.playarea' )?.classGame;
	game ||= Swiper?.current.classGame;
	import( './mods/customize.js' ).then( module => module.default( game ) );
};

Core.checkAuth = type => {
	// if( LOCALTEST ) return Promise.resolve( 'noauthmodule' );
	return window.waitAuth( type );
};

function onResume() {
	let s = '=== RESUMED ===';
	focusLostCount++;
	if( window.pauseTime )
		s += ' Sleep time is ' + ((Date.now() - window.pauseTime) / 1000) + 's';
	log( s );
	window.pauseTime = 0;
}

// Set timer for keepalive message sending if registered
setInterval( () => {
	Core.checkAlive( 20000 );		// Send alive to server at least one time per 10sec
}, 10000 );

function onPause() {
	log( '=== PAUSED ===' );
	window.pauseTime = Date.now();
}

let nextCheckMs = 0;
Core.checkAppUpdate = async () => {
	if( Date.now()<nextCheckMs ) return;
	nextCheckMs = Date.now() + 10 * 60 * 1000;

	/*
		if( window.plugins?.updatePlugin ) {
			log( 'Start [periodical] new check APP update' );
			window.plugins?.updatePlugin.update( {
				flexibleUpdateStalenessDays: 0,
				immediateUpdateStalenessDays: 30
			} );
			return;
		}

	*/
	if( window.cordova?.plugins?.inappupdate ) {
		log( 'Start [periodical] check APP update cordova-plugin-codeplay-in-app-update' );
		cordova.plugins.inappupdate.update( 'flexible', () => {
			log( 'Update started' );
		} );
	}

	// Manual apk installation
	if( window.ApkUpdater ) checkApkWithoutStore();
};

async function checkApkWithoutStore() {
	log( 'Checking APKupdate without store' );
	let myversion = await ApkUpdater.getInstalledVersion();
	log( 'My app info ' + JSON.stringify( myversion ) );
	let versions = await (await fetch( coreParams.apkconfig )).json();
	log( 'My version ' + myversion.version.name + ', versions is ' + JSON.stringify( versions ) );
	let myval = myversion.version.name.split( '.' ).reduce( ( sum, val ) => sum * 1000 + (+val), 0 ),
		relval = versions.release.split( '.' ).reduce( ( sum, val ) => sum * 1000 + (+val), 0 );
	if( myval<relval ) {
		try {
			log( 'Downloading update from ' + coreParams.apkpath );
			let dwnl = await ApkUpdater.download( coreParams.apkpath );
			log( 'update downloaded ' + JSON.stringify( dwnl ) );
			await ApkUpdater.install();
		} catch( e ) {
			log( e.message + '\n' + e.stack );
		}
	}
}

function onDeviceReady() {
	log( 'Device is ready. cordova.platformId=' + cordova.platformId + '. device.platform=' + device.platform );
	let s = 'Window plugins: ';
	for( let k in window.plugins ) s += k + ' ';
	log( s );

	// установка IOS
	if( cordova.platformId==='ios' ) {
		log( "window.IOS=true" );
		window.IOS = true;
	}

	if( cordova.inAppBrowser )
		window.open = cordova.inappBrowser.open;

	log( 'Disable force dark mode' );
	window.WebViewManager?.setForceDarkAllowed(false)
		.then(console.info)
		.catch(console.error);

	// Проверим обновления
	Core.checkAppUpdate();

	if( cordova.openwith ) {
		cordova.openwith.init( initSuccess, initError );
		cordova.openwith.setVerbosity( cordova.openwith.DEBUG );

		function initSuccess() {
			console.log( 'Openwith init success!' );
		}

		function initError( err ) {
			console.log( 'Openwith init failed: ' + err );
		}

		cordova.openwith.addHandler( intentHandler );

		async function intentHandler( intent ) {
			log( 'intent received: ' + JSON.stringify( intent ) );
			let mod = await import( './mods/intent.js' );
			mod.intentReceived( intent );
			if( intent.exit )
				cordova.openwith.exit();
		}
	}

	document.documentElement.classList.add( 'cordova' );

	window.DEVICEREADY = true;
	if( window.cache )
		window.cache.clear( () => log( 'Debug: window.cache cleared' ), () => log( 'Debug: error clearing window.cashe' ) );

	if( window.plugins.toast ) {
		window.Toast = ( msg, dur, pos ) => window.plugins.toast.show( localize( msg ), dur || 'long', pos || 'bottom' );
	}

	if( window.plugins.OneSignal ) {
		log( 'Plugin OneSignal detected' );
		import( './mods/onesignal.js' );
	}

	// Enable autostart for notifications
	if( cordova.plugins.autoStart ) {
		log( 'Setting autostart to true' );
		cordova.plugins.autoStart.enable();
	}

	cordova.plugins.IsDebug?.getIsDebug( function( isdebug ) {
		window.DEBUG = isdebug;
		if( isdebug ) log( 'Debug version of APP' );
	}, function( err ) {
		log( 'Failed to detect DEBUG: ' + JSON.stringify( err ) );
	} );

	if( window.chrome?.power ) {
		log( 'Chrome Power management is possible' );
	}
	if( window.powermanagement ) {
		log( 'I can use cordova powermanagement' );
		// window.powermanagement.acquire();
		// setTimeout( 10000, window.powermanagement.release );
	}

	document.addEventListener( 'backbutton', () => {
		log( 'back button pressed' );
		Core.goBack() || navigator.app.exitApp();
	}, false );

	document.addEventListener( 'menubutton', () => {
		log( 'menu button pressed' );
		Core.showSettings();
	}, false );

	if( cordova.getAppVersion ) (async function() {
		cordova.getAppVersion.getVersionNumber( version => cordova.versionNumber = version );
		cordova.packageName = await cordova.getAppVersion.getPackageName();
		cordova.appName = await cordova.getAppVersion.getAppName();
	}());

	navigator.splashscreen?.hide();

	if( window.universalLinks ) {
		universalLinks.subscribe( null, function( eventData ) {
			// do some work
			log( 'Deep link with ' + eventData.url );
			Core.itemCode = eventData.url.slice( eventData.url.indexOf( '?' ) + 1 );
			goLocation( Core.itemCode );
		} );
	}

	document.addEventListener( 'pause', onPause, false );
	document.addEventListener( 'resume', onResume, false );

	if( window.admob ) {
		admobStart();
	}

	modules.Auth?.firstAuth();
}

async function admobStart() {
	log( 'Starting admob...' );
	await admob.start();

	log( 'Admob creating banner...' );
	let banner = new admob.BannerAd( {
		adUnitId: 'ca-app-pub-3940256099942544/6300978111'
		// adUnitId: 'ca-app-pub-2636741987184803/7046739632'
	} );

	log( 'Banner created ' + banner );

	if( banner ) {
		banner.on( 'impression', async ( evt ) => {
			log( 'admob impression' );
			await banner.hide()
		} );

		await banner.show();
	}
}

function onkeydown( e ) {
	if( (e.key==='p' && e.ctrlKey) || e.keyCode===119 /* F8 */ ) {
		if( land==='navigator' || land==='manager' ) {
			e.preventDefault();
			Core.showSettings();
		}
		return false;
	}

	// Ctrl+B или F10
	if( e.keyCode===121 /* F10 */ || ((e.metaKey || e.ctrlKey) && (e.key==='b' || e.keyCode===66)) ) {
		e.preventDefault();
		bugReport();
		return false;
	}

	if( e.key==='ArrowLeft' && e.metaKey ) {
		if( (!document.activeElement ||
				(!document.activeElement.hasAttribute( 'contenteditable' )
					&& !['INPUT', 'TEXTAREA'].includes( document.activeElement.tagName )))
			&& Core.way?.length ) {
			Core.goBack();
			e.preventDefault();
			return false;
		}
		// e.preventDefault();
		return false;
	}
}

function customevent( e ) {
	log( `${e.type} onLine: ${navigator.onLine}. docvis: ${document.visibilityState}` );
}

window.addEventListener( 'touchend', e => {
	// Если 30 секунд молчали, сообщим серверу, что мы не спим
	if( Date.now() - window.lastInteractTime>30000 )
		Core.sendPlay( 'type=interact' );
}, { passive: true } );

window.addEventListener( 'keydown', onkeydown );
document.addEventListener( 'close', customevent );
document.addEventListener( 'error', customevent );

window.addEventListener( 'blur', e => {
	focusLostCount++;
	// log( 'Window blur ' + focusLostCount );
} );

document.addEventListener( 'visibilitychange', () => {
	// log( 'Visibility changed: ' + document.visibilityState );
	let hidden = document.visibilityState==='hidden';
	if( hidden ) {
		// Сообщим, что мы отключаемся
		focusLostCount++;
		Core.do( 'type=bye' );
	} else {
		Core.sendPlay( 'type=interact' );
		Core.checkAppUpdate();
	}
	if( Core.paused===hidden ) return;
	Core.paused = hidden;
	log( 'VISIBILITY: ' + document.visibilityState );
	fire( hidden ? 'onpause' : 'onresume' );
} );
//    document.addEventListener( 'offline', online );
//    document.addEventListener( 'online', online );

/*
if( !LOCALTEST ) {
	window.addEventListener( 'error', function( e ) {
		if( !e.error ) return;
		let stack = e.error.stack,
			message = e.error.toString();
		if( message==='out of memory' ) {
			// Firefox goes out of memory, force reload the page
			return location.reload( true );
		}
		if( stack ) message += '\n' + stack;
		window.bugReport( '--JS-- ' + message, e );
	} );
}
*/

window.onerror = ( msg, url, lineNumber, column, o ) => {
	log( 'Error ' + msg + ' at ' + url + ':' + lineNumber + ':' + column );
	return false;
};

Core.lang = (document.documentElement.lang || 'ru').toLowerCase();

Core.toserver = str => {
	if( !str ) return;
	fire( 'toserver', str );
};

let aliasesStatus;

Core.refreshAliases = force => {
	if( force!=='now' && aliasesStatus==='received' ) return false;
	if( !force && aliasesStatus==='requested' ) return true;
	aliasesStatus = 'requested';
	fire( 'toserver', 'GET aliases\n' );
	return true;
};

Subscribe.add( 'aliases', {
	all: o => {
		aliasesStatus = 'received';
		if( Core.aliases.parseMSET( o ) ) {
			renewAliases();
			delay( Core.storeAliases );
		}
		fire( 'aliasesreceived' );
	}
} );

Subscribe.addParser( 'command', ( data, minor ) => {
	if( minor==='authorize' )
		return Core.login && Core.login();
} );

Subscribe.addParser( '_me', {
	plays: o => {
		Core.plays = o || {};
	},
	noad: o => {
		window.NOADUIN = o ? UIN : 0;
		fire( 'checkads' );
	},
	admin: o => {
		window.ADMIN = Core.ADMIN = o;
		if( o ) fire( 'loggedin' );
	},
	fants: val => window.MYFANTS = Core.myfants = Number.isInteger( +val ) && { value: +val } || val || { value: 0 },
	canpayids: val => Core.canpayids = val,
	fantschange: o => {
		if( o.change ) {
			let ch = `<span style='color: ${+o.change>0 ? 'lightgreen' : 'orange'}'>${o.change}</span>`;
			toast( `<span>${ch} 💰 ${o.comment || ''}</span>` );
		}
	},
	pays: o => {
		log( 'Pays received' );
		window.myself.pays = Core.pays = o;
		for( let el of $$( '[data-hideifpayer]' ) ) {
			let t = el.dataset.hideifpayer;
			if( !t ) continue;
			el.makeVisible( !Core.isHallPayer( t ) );
		}
		fire( 'checkads' );
	},
	mytd: o => {
		if( Core.myTD.parseMSET( o ) ) fire( 'mytdchanged' );
	},
	myteams: checkMyTeams,
	allmutes: o => Core.myMutes.parseMSET( o ),
	allenemies: o => Core.myNoplay.parseMSET( o ),
	allnoplay: o => Core.myNoplay.parseMSET( o ),
	allfriends: o => {
		Core.myFriends.parseMSET( o );
		log( 'My friends count=' + Core.myFriends.size );
	},
	toast: str => toast( str ),
	message: o => chatMessage( o ),
	newmessage: o => chatMessage( o ),
	chat: o => {
		modules.chat?.routeMyself( o )
	},
	usermessage: o => {
		makeBigWindow( {
			repeatid: 'usermessage',
			title: o.caption,
			html: o.html
		} ).show();
	}
} );

async function chatMessage( o ) {
	(await import( './mods/chat.js' )).message( o );
}

function checkMyTeams( o ) {
	if( o ) Core.myTeamsMap.parseMSET( o );
	for( let t of Core.myTeamsMap ) {
		let team = User.setTeam( t[0] );
		let reg = t[1].match( /([^\$]*)(\$(.*?)(-?\d+(\.\d*)?))?$/ );
		if( reg ) {
			team.setMe( 'state', reg[1] );
			if( reg[2] ) {
				team.currsymbol = reg[3];
				team.setBalance( reg[4] );
			}
			// log( `Team ${t[0]} set ${team.balance} ${team.currency}` );
		}
		// Subscribe.add( )
	}
	fire( 'checkmyteams' );
	let jump = window.waitJumpMyClub;
	if( jump && Core.myTeamsMap.get( jump ) ) {
		log( 'Received information. Jumping into club ' + jump );
		window.waitJumpMyClub = null;
		goLocation( jump )
	}
}

Subscribe.addParser( 'user_*', ( data, minor, params ) => {
	if( !minor ) return;
	if( params.postfix!==window.UIN ) return;
	Subscribe.set( 'me.' + minor, data );
	switch( minor ) {
		case 'bans':
			Core.myBans = data;
			break;

		case 'regers':
			// В каких автоподборах зарегистрирован
			if( Core.myRegers.parseMSET( data ) )
				// if( parseMSET( data, self.regers ) )
				fire( 'regerschanged' );
			break;
		case 'tourplacing':
			Core.tourPlacing.parseMSET( data );
			fire( 'tourplacingchanged' );
			break;

		case 'games':
			// Играет в играх MSET
			for( let t of data.split( ' ' ) ) {
				if( !t ) continue;
				if( t[0]==='-' ) {
					Core.myGames.delete( t.slice( 1 ) );
					continue;
				}
				let parts = t.split( ':' );
				let idx = parts[0];
				if( Core.myGames.has( idx ) ) continue;
				Core.myGames.set( idx, null )
				import( './mods/vgame.js' ).then( mod => {
					new mod.Vgame( { id: parts[0], type: parts[1], autoshow: true } );
				} );
			}
			break;
	}
	if( minor.startsWith( 'event_' ) ) {
		fire( 'regerschanged', minor );
		if( LOCALTEST && data.participant_id )
			Subscribe.debugSub( `${minor}_t_${data.participant_id}` );
	}
} );

Core.isPremium = () => Core.pays && (Core.pays.premium + TIMESERVERDIFF>=Date.now()) || false;
Core.isHallPayer = hall => LOCALTEST || Core.pays && (Core.isPremium() || Core.pays.halls?.includes( hall ) || false);
Core.isClubPayer = () => Core.pays?.premium || Core.pays?.halls || Core.pays?.club || false;
Core.isReged = reger => Core.myRegers.has( reger.toString() );
Core.getTourPlace = ev => Core.tourPlacing.get( ev.toString() );
Core.countPlayTables = () => Object.keys( Core.plays ).length;

Core.getMyTeam = team => Core.myTeamsMap.get( team?.toString() );
Core.isRegistered = item => Core.myRegs.has( item );
Core.registerInGame = item => Core.do( 'register g=' + item );

let lastPlay;
Core.sendPlay = async ( data, nokeep ) => {
	if( nokeep ) {
		data += ' interacted=' + (Date.now() - window.lastInteractTime);
	} else {
		if( data ) window.lastInteractTime = Date.now();
		else data = lastPlay;
		if( !data ) return;
		data += ' focus=' + focusLostCount;
		lastPlay = data;
	}
	let res = await Core.do( data, 'PLAY' );
	if( !nokeep && res?.ok ) {
		lastPlay = null;
	}
	return res;
};

let allRequests = new Map, uniqIdent = 1;

async function doConfirm( confirm ) {
	await askConfirm( confirm.title );
	// Confirmation accepted
	if( confirm.action )
		Core.do( confirm.action );
	if( confirm.toast )
		toast( confirm.toast );
}

Core.do = ( data, action ) => {
	if( !window.UIN ) {
		// Не авторизованы, запрос не пройдет. Однако, некоторые запросы можно отправлять от гостя
	}
	return new Promise( mainresolve => {
		new Promise( resolve => {
			if( resolve ) allRequests.set( uniqIdent, resolve );
			let uuid = (!UIN || UIN<0) ? `-uuid=${UUID}` : '';
			let str = `CALL ${uuid} -tag=${uniqIdent} gambler.${(action || 'DO')} ${data}`;
			Core.toserver( str );
			uniqIdent++;
			lastPlayMs = Date.now();
		} ).then( json => {
			if( !json ) return;
			if( json.error && typeof json.error==='string' ) toast( json.error, 'long' );
			if( json.errcode==='noname' || json.errcode==='guest' ) {
				return fire( 'login' );
			}
			if( json.jump ) {
				// Открыть какой-то стол (игровой, просьба сервера)
				goLocation( json.jump );
			}
			let fants = json.fants || json.needfants && { need: json.needfants };
			if( fants ) {
				if( fants.forclub_id ) {
					// Need to deposit money (fants) to club account
					import( './mods/team.js' ).then( mod => mod.deposit( User.setTeam( fants.forclub_id ) || User.myself, {
						value: fants.need - (fants.available||0)
					} ) );
					return;
				}
				let ttl = json['payreason'];
				if( ttl ) ttl += '. ';
				let amount = fants.need;
				ttl += `{Necessary}: ${amount}`;
				Core.shopping( {
					ids: 'fants',
					reason: json.payreason,
					needfants: amount,
					title: ttl
				} );
				mainresolve?.( json );
				return;
			} else if( json.needloots ) {
				// Shopping!
				Core.shopping( {
					ids: json.needloots,
					reason: json.payreason
				} );
				return;
			} else if( json.badloots ) {
				// Shopping!
				Core.shopping( {
					ids: 'bad-' + json['badloots'],
					reason: json['payreason']
				} );
				return;
			} else if( json['needfixfio'] ) {
				if( json.needfixfio.id===UIN ) {
					// TODO: fixfio window
					import( './mods/fixfio.js' ).then( module => {
						module.provideFullname();
					} );
					return;
				}
			} else if( json.money || json.minmoney ) {
				let money = json.money || { need: json.minmoney, club: json.clubmoney };
				if( money.club ) {
					import( './mods/team.js' ).then( module => {
						module.chargeClubAccount( User.setTeam( money.club ), money );
					});
				}
			} else if( json.confirm ) {
				doConfirm( json.confirm );
			}
			if( json.toast ) {
				toast( json.toast );
			}
			// if( json.error && typeof json.error==='string' ) toast( json.error, 'long' );
			if( json.petition ) bugReport( 'Badmove' );

			if( mainresolve ) mainresolve( json );
		} );
	} );
};

dispatch( 'serverresponse', data => {
	let [ident] = data.split( ' ', 1 );
	if( !+ident ) return;
	let str = data.slice( ident.length + 1 );
	if( str[0]===';' ) return;
	let json;
	if( str==='error: forbidden' ) {
		json = { errcode: 'forbidden' };
	} else if( str[0]!=='{' ) {
		fire( 'fromserver', str );
		return;
	}
	if( !json ) try {
			json = JSON5.parse( str )
		} catch( e ) {
		log( 'J5 parse error ' + e.message + ' in ' + e.text );
	}
	if( json?.jump ) fire( 'swipeto', json.jump );
	let resolve = allRequests.get( +ident );
	if( resolve ) {
		// let json = JSON5.parse( data.slice( ident.length + 1 ) );
		// if( str[0]!=='{' ) return;
		try {
			resolve( json );
		} catch( e ) {
			return;
		}
	}
	for( let i = +ident; i--; ) allRequests.delete( i );
} );

Core.simpleMessage = ( element, message ) => {
	// Send message behind the element
};

Core.checkAlive = ms => {
	if( Date.now()>=lastPlayMs + ms ) Core.sendPlay( 'type=void', true );
};

Core.login = async () => {
	(await import( './mods/auth.js' )).signin();
};

Core.checkLocation = async loc => {
	loc = loc.toString();
	if( loc.includes( ':' ) ) {
		return loc.split( ':' )[1];
	}
	if( loc.match( /^(room[\._]|team[\._]|game[\._]|user[\._]|T|C)(\d+)$/ ) )
		return loc.replace( '.', '_' ); //.replace( 'T', 'event_' ).replace( 'C', 'team_' );
	if( loc.startsWith( 'game_' ) ) return loc.replace( 'game-', 'game.' );
	if( loc.startsWith( 't-' ) ) return loc.replace( 't-', 'team_' );
	if( !loc || loc.includes( '.html' ) ) return window.domainData?.root || coreParams['tableviewstart'] || '';
	// if( loc.startsWith( 'room.' ) ) return loc.slice( 5 );
	if( +loc ) return +loc<100? 'room_' + loc : 'event_' + loc;
	if( Core.aliases.has( loc ) ) return Core.aliases.get( loc );
	let resolve = await API( '/resolve', { location: loc }, 'internal' );
	if( resolve?.item ) {
		// Здесь не сохраняем alias, так как он придет в location
		return resolve.item;
	}
	log( 'Not found alias ' + loc + ': ' + JSON.stringify( resolve ) );
	return window.domainData?.root || coreParams.tableviewstart || '';   // Фойе
	/*
		return new Promise( resolve => {
			Core.refreshAliases( 'now' );
			dispatch( 'aliasesreceived',
				() => resolve( Core.aliases.get( loc ) || loc ) );
		} );
	*/
};

Core.setLocation = ( loc, title ) => {
	log( 'setLocation ' + loc );
	if( Core.config['nolocation'] ) return;
	if( window.peConfig?.fixedLocation ) return;
	// Если локализация не сработала, не показываем фигурные скобки в заголовке
	// Загрузиться туда они всё равно не смогут
	if( title ) document.title = localize( title ).replace( /[\}\{]/g, '' );
	// if( Core.aliases.has( loc ) ) loc = Core.aliases.get( loc );
	if( Core.backAlias.has( loc ) ) {
		loc = Core.backAlias.get( loc );
		// log( 'Get location from backAlias ' + loc );
	}
	if( loc ) {
		loc = loc.replace( '??', '?' );
		if( loc.slice( -1 )==='/' ) loc = loc.slice( 0, -1 );
		loc = loc.replace( 't-.', '' ).replace( 'team.', '' );
		// if( loc.startsWith( 't-' ) ) loc = loc.replace( 't-', 'team.' );
	}
	if( Core.location!==loc ) {
		log( 'Changing location ' + loc );
		Core.location = loc;
		let floc = (Core.pathPrefix + (loc || '')).replace( '??', '?' );
		if( floc.slice( -1 )==='/' ) floc = floc.slice( 0, -1 );

		// log( 'Go location2 ' + loc );
		if( floc[0]!=='/' && window.location.protocol==='https:' ) {
			floc = '/' + floc;
			log( 'Adding slash to /newlocation' );
		}
		try {
			setMyLocation( floc || '/' );
		} catch( e ) {
			log( 'Failed to set location ' + floc );
		}
		document.body.dataset.location = floc;
	}
};

dispatch( 'connected', () => {
	document.body.classList.add( 'connected' );
	document.body.classList.remove( 'disconnected' );
	// var a = document.getElementsByClassName( 'dependsonconnect' );
	// for( var i = a.length; i--; ) a[i].setAttribute( 'connected', true );
	// ALIASы прочтем один раз
	if( !localaliases || aliasesStatus==='requested' ) {
		Core.refreshAliases( 'now' );
	}
	Core.sendPlay();
	if( window.interactTime )
		Core.sendPlay( 'type=interact data=' + (Date.now() - window.interactTime) );
} );

dispatch( 'disconnected', () => {
	if( !Core.UNLOAD ) {
		document.body.classList.remove( 'connected' );
		document.body.classList.add( 'disconnected' );
	}
	// var a = document.getElementsByClassName( 'dependsonconnect' );
	// for( var i = a.length; i--; ) a[i].setAttribute( 'connected', true );
} );

HTMLElement.prototype.track = function( name, value ) {
	this.dataset[name] = value;
	this.ontrack?.( name, value, this );
};

function setAttr( name, value, justcheck ) {
	if( justcheck===true && Core.globalAttr[name] ) return false;
	if( Core.globalAttr[name]===value ) return false;
	// document.body.dataset[name] = value;
	// Lower "for" instead of "for .. lef .. of" because some
	// old browsers do not support forEach for NodeList
	// (even babel transpiler can not help with it)
	for( let o of $$( `[data-track~="${name}"]` ) )
		o.track( name, value );

	Core.globalAttr[name] = value;
	if( justcheck!=='onstart' ) {
		log( `Storing attributes` );
		Core.globalAttr.lastModified = new Date().toString();
		localStorage.attributes = JSON.stringify( Core.globalAttr );
	}
	if( name==='sounds' ) window.NOSOUNDS = !value;
	return true;
}

Core.playSound = id => {
	if( !Core.globalAttr['sounds'] ) return;
	playSound( id );
};

Core.setAttr = setAttr;
Core.track = ( element, name, fixed ) => {
	if( fixed ) {
		delete element.dataset.track;
		element.dataset[name] = fixed;
	} else {
		element.dataset.track = name;
		if( Core.globalAttr[name] )
			element.track( name, Core.globalAttr[name] );
	}
};

Core.auth = {};

Core.setAuth = o => {
	Core.auth = o;
	AUTH = o.uid + ':' + o.long_token;
	if( o.info ) Core.myfants.value = +o.info.fants;
	log( 'Core auth set: ' + JSON5.stringify( o ) );
	log( 'AUTH=' + AUTH );
	if( o.uid && o.info?.showname && window.User ) {
		let u = window.User.set( o.uid, {
			name: o.info.showname,
			picture: o.info.picture || o.info.avatarid
		} );
		if( o.id ) u.emailid = o.id;
		window.MYNAME = o.info.showname;
	}
};

// self.gameGroups = [];
// self.freeGames = [];

Core.isFriend = id => Core.myFriends.has( id.toString() );
Core.isMute = id => id && (Core.myMutes.has( id.toString() ) || Core.myNoplay.has( id.toString() ));
Core.mute = id => Core.myMutes.add( id.toString() );

// Preparse gmbData
if( window.gmbData ) {
	log( 'STORED INPUT' );
	log( window.gmbData );
	fire( 'fromserver', window.gmbData );
}

function detectSocial() {
	let appType = Core.params['apptype'] || 'web',
		socials = {
			fb: 'https://apps.facebook.com/',
			vk: 'https://vk.com/',
			ok: 'https://ok.ru/'
		};


	log( 'Referrer is ' + document.referrer + ', apptype is ' + appType );
	if( !window.cordova ) {
		Core.firstReferrer = localStorage['firstreferrer'];
		if( !Core.firstReferrer )
			localStorage['firstreferrer'] = Core.firstReferrer = document.referrer;
	}

/*
	for( let soc in socials ) {
		if( !document.referrer.includes( socials[soc] ) ) continue;
		log( 'Social detected: ' + soc );
		storagePrefix = soc + '_';
		Core.params['autologin'] = true;
		Core.params['social'] = true;
		if( soc==='fb' ) import( './social_fb.js' );
		if( soc==='vk' ) import( './social_vk.js' );
		if( soc==='ok' ) import( './social_ok.js' );
		break;
	}
*/
}

detectSocial();

// Нельзя оставлять только addEventListener, прекращает работать (?)
// Нельзя переходить на DOMContentLoaded, тоже перестает.
// Видимо, не успевают загрузиться другие модули, необходимые для жизнедеятельности
if( document.readyState==='complete' )
	onload();
else
	window.addEventListener( 'load', onload );

if( 'onhashchange' in window )
	window.onhashchange = ( event ) => {
		log( 'New HASH ' + location.hash );
		event.preventDefault();
		event.stopPropagation();
		return false;
	};

window.shopping = Core.shopping = async ( ids, reason, title ) => {
	log( 'Start shopping' );
	let params = typeof ids==='object' ? ids : {
		ids: ids,
		reason: reason,
		title: title
	};
	params.ids = params.ids?.toLowerCase() || '';

	import( './mods/shopping.js' ).then( mod => mod.default( params ) );
};

Core.apiRequest = window.API;

Core.showOffers = () => Core.offersBig && Core.offersBig.show();
Core.hideOffers = () => Core.offersBig && Core.offersBig.hide();

Core.addOffer = async offerpanel => {
	if( !Core.offersBig ) {
		Core.offersBig = makeBigWindow();
		construct( '.offers', Core.offersBig );
		// Неубираемый по клику bigWindow
	}
	let panel = Core.offersBig.firstChild;
	panel.appendChild( offerpanel );
};


if( localStorage['signingUP'] ) {
	log( 'Stored Signup ' + localStorage['signingUP'] );
}

dispatch( 'loggedout', () => {
	for( let k in Core ) {
		if( k.startsWith( 'my' ) && (typeof Core[k]==='object') && ('clear' in Core[k]) )
			Core[k].clear();
	}
	Core.myBans = '';
} );

dispatch( 'loggedin', () => {
	// Get unread messages
	// if( !window.isGuest() )
	// 	API( '/getunreadcount', {}, 'internal' );

	// Signin through gate
	if( UIN && !(+UIN<0) ) {
		elephCore?.do( 'type=login' );
	}

	/*
		// Ожидает ли кто-нибудь
		if( waitingAuthResolve ) {
			let tmp = waitingAuthResolve;
			waitingAuthResolve = null;
			tmp();
		}
	*/
	// Core.sendPlay();
} );

function setRebootInfo( o ) {
	window.REBOOTTIME = checkMS( o );
	checkRebootTimer();
}

function checkRebootTimer() {
	if( !window.REBOOTTIME || REBOOTTIME<Date.now() ) {
		window.REBOOTCOUNTER?.hide();
		return;
	}
	if( REBOOTTIME-Date.now()<60000 ) {
		window.REBOOTCOUNTER ||= html( `<span is='neo-timer' class='display_none visible' style='position: fixed; right: 0; bottom: 0; background: black; color: yellow; font-size: 2rem; padding: 1rem'></span>`, document.body );
		REBOOTCOUNTER.dataset.to = REBOOTTIME;
	} else
		setTimeout( checkRebootTimer, window.REBOOTTIME - 60000 );
}

Subscribe.add( 'core', {
	message: str => {
		if( str==='launched' )
			Core.sendPlay();
	},
	full: o => Core.full = o,
	rebootat: setRebootInfo
} );

/*
Core.validatePurchase = async function( data ) {
	log( 'Validating purchase ' + JSON.stringify( data ) );
	let resp = await API( '/validatepurchase', data );
	if( !resp ) return;
	log( 'Validated ' + JSON.stringify( resp ) );
	if( resp.consume && data.productType ) {
		log( 'Consuming ' + data.product );
		inAppPurchase.consume( data.productType, data.receipt, data.signature )
			.then( () => {
				log( 'Consumed OK' );
			} )
			.catch( err => {
				log( 'Consume FAIL ' + JSON.stringify( err ) );
			} );
	}
	return resp;
};

*/
let jumpParam, jumpTimer, jumpRepeats;
Core.setAutoJump = o => {
	if( jumpTimer ) clearTimeout( jumpTimer );
	jumpTimer = null;
	jumpParam = o;
	jumpRepeats = 0;
	if( !o ) return;

	log( 'Preparing auto jump to ' + jumpParam.request );
	jumpTimer = setTimeout( makeJump, 3000 );
}

async function makeJump() {
	jumpTimer = null;
	if( !jumpParam ) return log( 'makeJump without jumpParam' );
	log( 'makeJump with jumpParam ' + JSON.stringify( jumpParam ) );
	// Если есть собственные игровые столы, не прыгаем
	if( Core.countPlayTables() )
		return log( 'Jump canceled (my tables)' );
	let reged = Core.isReged( jumpParam.event );
	if( reged ) {
		return log( 'Jump canceled because I am in event' );
	}
	if( jumpParam.request ) {
		let jp = jumpParam,
			j = await Core.do( jumpParam.request );

		if( j && j.item && j.item!==Core.location ) {
			// if( j.participant ) {
			// 	return log( 'Dont jumping because participant (some kind of error)' );
			// }
			if( !+j.item ) toast( '{Jump}!' );
			goLocation( j.item );
		}
		log( 'Value of j now is ' + JSON.stringify( j ) );
		if( j.wait || j.wakeuptime ) {
			// Если играем в этом турнире, то не прыгаем никуда между турами
			let myplace = Core.getTourPlace( jp.event ) || Core.isReged( jp.event );
			log( 'I am reged: ' + myplace );
			if( myplace ) return;
			if( j.participant ) {
				log( 'My regs: ' + JSON.stringify( Core.myRegers ) );
				// bugReport( 'No jump alert' );
				return log( 'no jumping because participant (when wait)' );
			}
			// Если задано время, сработать когда истечет (пока 2 сек)
			jumpRepeats++;
			log( `Repeating jump ${jumpRepeats}, maximum 10 times` );
			if( jumpRepeats>10 ) return;
			jumpParam = jp;			// Необходимо восстановить
			let tm = jumpRepeats<5 ? 3000 : 4000,
				stm = tm;
			if( j.wakeuptime ) {
				stm = checkMS( j.wakeuptime ) + TIMESERVERDIFF - Date.now() + 1000;
				log( 'Calculated interval=' + stm + 'ms' );
			}
			jumpTimer = setTimeout( makeJump, stm>0 && stm<=60000 ? stm : tm );
		}
	}
}

Core.canpayfor = payids => {
	if( !payids || !Core.canpayids ) return false;
	return payids.split( ';' ).find( x => Core.canpayids.includes( ';' + x + ';' ) );
};

dispatch( 'authjson', json => {
	// Logout as guest
	if( UIN && UIN!==json.uid )
		Core.do( 'type=logout' );

	// Send authorization string to server
	Core.setAuth( json );
	if( json.token ) {
		window.setLoginStr( 'LOGIN -tag=token uuid=' + window.UUID + ' token=' + json.token );
		// АПП всегда делает тоаст
		if( window.cordova && json.uid && !json.guest )
			toast( '{Welcome} ' + (json.info.showname || json.uid), 'short' );
	} else {
		window.setLoginStr();
	}
} );

// С cordova не нужно делать гостевой логин, т.к. он сам будет сделан по deviceready
if( !window.cordova )
	modules.Auth?.firstAuth();

dispatch( 'loggedin', () => {
	if( LOCALTEST )
		Subscribe.set( '_me.invites', [ { iid: 124, id: 'tourpair.949832.42316.1515512', f: '42316:9f8f01bc6f8cafb0b2ac9da95b77fd86:BOPOH', sender: { uin: 42316, nick: 'BOPOH', name: 'Дмитрий Никитин', magicid: '9f8f01bc6f8cafb0b2ac9da95b77fd86' }, from: '42316', fromnick: 'BOPOH',  t: '1515512::Patvakan', recipient: { uin: 1515512, nick: 'Patvakan', name: '' }, to: '1515512', data: { id: '949832', title: 'КЛН ФЛ Nardegammon' }, tourid: 949832, time: 0, name: 'КЛН ФЛ Nardegammon', game: 'NLBG' } ] );
});

if( LOCALTEST )
	window._testintent = int => import( './mods/intent.js' ).then( mod => mod.intent( int ) );

window.checkOverlap = function( mark, root ) {
	let res = '', cells = 0;
	// Check any visible controls for overlap
	if( mark )
		for( let el of $$( '.overlapper,.overlapped' ) )
			el.classList.remove( 'overlapper', 'overlapped' );
	for( let el of (root || window).$$( '.likeabutton,button,.suitcode.clickable' ) ) {
		if( el.offsetParent===null ) continue;		// Hidden
		if( el.closest( '.nooverlapcheck' ) ) continue;
		let rect = el.getBoundingClientRect(),
			w4 = rect.width*0.25, h4 = rect.height*0.25,
			corners = [ [ rect.x+w4, rect.y + h4 ], [ rect.right-w4, rect.y + h4 ],
				[ rect.x+w4, rect.bottom - h4 ], [ rect.right-w4, rect.bottom - h4 ] ];

		for( let corn of corners ) {
			let eltop = document.elementFromPoint( corn[0], corn[1] );
			if( !eltop ) break;
			if( el.contains( eltop ) ) continue;
			if( eltop.contains( el ) ) continue;
			if( eltop.closest( '.bigwindow' ) ) continue;
			if( eltop.closest( '.table_statusbar' ) ) continue;
			// Overlapping!
			res += `Element ${JSON.stringify(el.dataset)}(${el.className}) overlapped by ${eltop.tagName}[${JSON.stringify(eltop.dataset)}](${eltop.className})\n`;
			cells++;
			if( mark ) {
				el.classList.add( 'overlapped' );
				eltop.classList.add( 'overlapper' );
			}
		}
	}
	if( res ) {
		console.log( res );
		res += cells + ' elements checked';
	}
	return res;
}